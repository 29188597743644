<template>
  <a :href="videoLink" target="_blank" class="video-guide-thumb">
    <div class="img-holder">
      <img :src="require('@/assets/images/video-guide.webp')" alt="" />
      <span class="video-guide-thumb__txt">
        <span
          class="video-guide-thumb__title"
          :class="{ sm: $i18n.locale === 'es' || $i18n.locale === 'uk' }"
          v-html="title"
        />
        <span class="video-guide-thumb__desc">{{ description }}</span>
      </span>
    </div>
  </a>
</template>

<script>
export default {
  name: "VideoGuide",
  props: {
    title: String,
    description: String,
    videoLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.video-guide-thumb {
  max-width: 100%;
  width: inherit;
  height: auto;
  display: block;
  margin-top: 20px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .img-holder {
    display: block;
    overflow: hidden;
    position: relative;
    padding-bottom: 52%;
    border-radius: 5px;
  }
}

.video-guide-thumb {
  &__txt {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 60%;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #20f6c3;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1.5;
  }
  &__title {
    color: #fff;
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 3px;
    &.sm {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .video-guide-thumb {
    max-width: 548px;
  }
}

@media screen and (max-width: 600px) {
  .video-guide-thumb__title {
    font-size: 20px;
    &.es {
      font-size: 18px;
    }
  }
}
</style>