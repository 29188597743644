<template>
  <div class="content content_in">
    <template v-if="hasAccess">
      <form class="row">
        <div class="col-12 col-xl-6">
          <div class="title-row row justify-content-between">
            <div class="col-12 col-md-auto">
              <h1>
                {{ isEdit ? $t("platform.edit_platform") : $t("platform.create_new") }}
              </h1>
            </div>
          </div>
          <VideoGuide class="d-xl-none" :title="$t('platform.how_to_create_platform_guide')" :description="$t('platform.video')" :videoLink="siteVideoGuide" />
          <div class="d-xl-none">
            <div class="mt-3 links-wrapper">
              <a target="_blank" href="https://guide.partners.house/keitaro">
                <img :src="require('@/assets/images/monetization/keitaro.svg')" alt="keitaro logo" height="22px" />
                <span> {{ $t("platform.arbitrage.keitaro") }} </span>
                <svg v-svg class="ml-2" symbol="arrow" size="0 0 17 17" role="info"></svg>
              </a>
              <a target="_blank" href="https://guide.partners.house/binom">
                <img :src="require(`@/assets/images/monetization/${isDarkTheme ? 'binom-d.png' : 'binom.webp'}`)" alt="binom logo" height="41px" />
                <span>{{ $t("platform.arbitrage.binom") }}</span>
                <svg v-svg class="ml-2" symbol="arrow" size="0 0 17 17" role="info"></svg>
              </a>
            </div>
          </div>
          <div class="sep _h10"></div>

          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label">
                  {{ $t("platform.arbitrage.name") }}
                </div>
                <div class="form-item__field">
                  <input ref="name" v-model="platform.name" type="text" :class="{ invalid: $v.platform.name.$error }" />
                  <small v-if="$v.platform.name.$error && (!$v.platform.name.minLength || platform.name === '')" class="error">{{ $t("min_len_pass", { number: "3" }) }}</small>
                  <small v-if="$v.platform.name.$error && !$v.platform.name.maxLength" class="error">{{
                    $t("max_len_pass", {
                      number: 52,
                    })
                  }}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label">
                  {{ $t("platform.payment_model") }}
                </div>
                <div class="form-item__field">
                  <custom-multiselect
                    v-model="platform.payment_model"
                    :list="paymentOptions"
                    :allowEmpty="false"
                    :disabled="isEdit"
                    class="payment"
                    :class="{
                      disabled_cpl: !user.is_cpl_payment_model_allowed,
                      invalid: $v.platform.payment_model.$error,
                    }"
                  >
                    <template v-slot:block="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>{{ props.value.title }}</span>
                      <br />
                      <small class="hint" v-if="props.value.title === 'Revshare'">
                        {{ $t("platform.revshare_option") }}
                      </small>
                      <small class="hint" v-if="props.value.title === 'CPL'">
                        {{ user.is_cpl_payment_model_allowed ? $t("platform.cpl_option") : $t("platform.cpl_option_ask") }}
                      </small>
                    </template>
                  </custom-multiselect>
                  <small class="error" v-if="$v.platform.payment_model.$error">
                    {{ $t("platform.choose_paymet_model") }}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="form-item__label mt-4">
            {{ $t("platform.arbitrage.accept") }}
          </div>
          <div class="row ml-1 mt-4 traffback">
            <div class="radio" @click="platform.tb_subscription = 'default'">
              <input type="radio" v-model="platform.tb_subscription" name="default" checked />
              <div class="radio__visible" :class="{ active: platform.tb_subscription === 'default' }"></div>
              <div class="form-item__label mb-0 info-popup mr-4">
                {{ $t("platform.keep_complexity") }}
                <span
                  v-tooltip="{
                    content: $t('platform.keep_complexity_tooltip'),
                    triggers: ['hover', 'click'],
                  }"
                >
                  <svg v-svg symbol="info" size="0 0 21 21" role="info"></svg>
                </span>
              </div>
            </div>

            <div class="radio" @click="platform.tb_subscription = 'partners'">
              <input type="radio" v-model="platform.tb_subscription" name="partners" checked />
              <div class="radio__visible" :class="{ active: platform.tb_subscription === 'partners' }"></div>
              {{ $t("platform.monetization_ph") }}
            </div>

            <div class="radio" @click="platform.tb_subscription = 'user'">
              <input type="radio" v-model="platform.tb_subscription" name="user" />
              <div class="radio__visible" :class="{ active: platform.tb_subscription === 'user' }"></div>
              {{ $t("platform.send_tb_link") }}
            </div>

            <TraffbackWithMacros v-if="platform.tb_subscription === 'user'" v-model="platform.tb_subscription_url" @addMacros="addMacros" :macroses="tbMacroses" :invalid="$v.platform.tb_subscription_url.$error || !$v.platform.tb_subscription_url.url" :title="'tb_subscription_url'" />
          </div>

          <div class="form-item__label mt-5">
            {{ $t("platform.arbitrage.reject") }}
          </div>

          <div class="row ml-1 mt-4 traffback">
            <div class="radio" @click="platform.tb_complexity = 'user'">
              <input type="radio" v-model="platform.tb_complexity" name="user2" checked />
              <div class="radio__visible" :class="{ active: platform.tb_complexity === 'user' }"></div>
              {{ $t("platform.send_tb_link") }}
            </div>

            <div class="radio" @click="platform.tb_complexity = 'partners'">
              <input type="radio" v-model="platform.tb_complexity" name="partners" />
              <div class="radio__visible" :class="{ active: platform.tb_complexity === 'partners' }"></div>
              {{ $t("platform.monetization_ph") }}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <TraffbackWithMacros v-if="platform.tb_complexity === 'user'" v-model="platform.tb_complexity_url" @addMacros="addMacros" :macroses="tbMacroses" :invalid="$v.platform.tb_complexity_url.$error || !$v.platform.tb_complexity_url.url" :title="'tb_complexity_url'" />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label">
                  {{ $t("platform.arbitrage.redirect_type") }}
                </div>
                <div class="form-item__field">
                  <custom-multiselect v-model="platform.redirect_type" :list="redirectMode" class="payment" :allowEmpty="false">
                    <template v-slot:block="props">
                      <span>
                        {{ formatLabel(props.value.title) }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>
                        {{ formatLabel(props.value.title) }}
                      </span>
                    </template>
                  </custom-multiselect>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label">
                  {{ $t("platform.mailing_frequency") }}
                </div>
                <div class="form-item__field">
                  <custom-multiselect v-model="platform.mailing_frequency" :list="mailings" :allowEmpty="false">
                    <template v-slot:block="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                  </custom-multiselect>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label info-popup">
                  {{ $t("platform.postback") }}
                  <span
                    v-tooltip="{
                      content: $t('platform.postback_pop_up'),
                      triggers: ['hover', 'click'],
                    }"
                  >
                    <svg v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                  </span>
                </div>
                <div class="form-item__field mb-2">
                  <TraffbackWithMacros class="p-0" v-model="platform.postback_url" @addMacros="addMacros" :macroses="macroses" :invalid="$v.platform.postback_url.$invalid" :title="'postback_url'" />
                </div>
                <div class="form-item__field mb-2">
                  <div class="form-item__label">
                    {{ $t("platform.send_postback_to") }}
                  </div>

                  <custom-multiselect :list="postback_format_list" v-model="postback_format" :allowEmpty="true" :multiply="true">
                    <template v-slot:block="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                  </custom-multiselect>
                </div>
              </div>

              <label>{{ $t("platforms.ready_templates") }}</label>
              <custom-multiselect v-model="templateModel" :list="templates" class="templates mt-1" :allowEmpty="true" :searchBy="'name'">
                <template v-slot:block="props">
                  <span v-if="props.value">
                    {{ props.value.name }}
                  </span>
                </template>
                <template v-slot:list="props">
                  <span>
                    {{ props.value.name }}
                  </span>
                </template>
              </custom-multiselect>
              <div class="col-12 pt-3">
                <CheckBoxWrapper v-model="platform.enable_in_page_ads" :text="$t('platform.site.turn_inpage_ad')" />
              </div>
              <div class="col-12 pt-3">
                <CheckBoxWrapper v-model="platform.enable_onclick_ads" :text="$t('platform.site.turn_onclick_ad')" />
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-md-6 col-xl-4">
              <div class="form-item">
                <div class="form-item__label info-popup">
                  {{ $t("platform.arbitrage.difficult") }}
                  <span
                    v-tooltip="{
                      content: $t('platform.difficult_pop_up'),
                      triggers: ['hover', 'click'],
                    }"
                  >
                    <svg v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                  </span>
                </div>
                <div class="form-item__field">
                  <custom-multiselect v-model="platform.complexity" :list="[1, 2, 3, 4, 5]" :allowEmpty="false">
                    <template v-slot:block="props">
                      <span>
                        {{ props.value }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>
                        {{ props.value }}
                      </span>
                    </template>
                  </custom-multiselect>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 align-self-end pb-2 fix-height" :class="{ 'small-height': !platform.enable_onclick_ads }">
              <div class="form-item">
                <div class="form-item__field">
                  <label class="checkbox" :class="{ disabled: platform.enable_onclick_ads }">
                    <input type="checkbox" :disabled="platform.enable_onclick_ads" v-model="platform.fullscreen" />
                    <span class="checkbox__visible"></span>
                    <span class="checkbox__text">{{ $t("platform.arbitrage.fullsize_mode") }}</span>
                  </label>
                </div>
                <div class="warn-block" v-if="platform.enable_onclick_ads">
                  <svg class="icon" v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                  <p>
                    {{ $t("platform.arbitrage.not_allowed_fullsize_mode") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row flex-row-reverse justify-content-end d-xl-flex">
            <div class="col-12 d-flex pb-2 flex-wrap align-items-start gap-3">
              <div v-if="platform.payment_model && platform.payment_model.value === 0" class="col-12 col-lg-5 form-item pb-0 d-flex align-items-center">
                <div class="form-item__field">
                  <CheckBoxWrapper v-model="platform.feedsSales" :text="$t('platform.sales_on_feeds')" />
                </div>
              </div>
              <div class="col-12 col-lg-5 form-item pb-0 d-flex align-items-center">
                <div class="form-item__field">
                  <CheckBoxWrapper v-model="platform.redirect_to_actual_domain" :text="$t('platform.arbitrage.send_to_current_domain')" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-auto" v-if="platform.redirect_to_actual_domain">
              <div class="form-item__field w-100">
                <span>{{ $t("platform.arbitrage.domain_strategy") }}</span>

                <custom-multiselect v-model="platform.domain_rotation" :list="rotationMode" class="payment" :allowEmpty="false">
                  <template v-slot:block="props">
                    <span>
                      <span>{{ formatLabel(props.value.title) }}</span>
                    </span>
                  </template>
                  <template v-slot:list="props">
                    <span>{{ formatLabel(props.value.title) }}</span>
                    <br />
                    <small class="option-hint d-block">{{ displayOptionHint(props.value.value) }}</small>
                  </template>
                </custom-multiselect>
              </div>
              <div class="warn-block mb-5">
                <svg class="icon" v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                <p>
                  {{ $t("platform.arbitrage.rotation_warning") }}
                </p>
              </div>
            </div>
            <div class="col-12 col-md-auto d-none-mobile">
              <div class="form-item pb-0">
                <div class="form-item__field">
                  <button type="submit" @click.prevent="createArbitrage" class="btn _mob100">
                    {{ $t("platform.save_platform") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-6 h-fit d-flex flex-wrap">
          <VideoGuide class="d-none d-xl-block" :title="$t('platform.how_to_create_platform_guide')" :description="$t('platform.video')" :videoLink="siteVideoGuide" />
          <div class="d-none d-xl-block w-100">
            <div class="mt-3 links-wrapper">
              <a target="_blank" href="https://guide.partners.house/keitaro">
                <img :src="require('@/assets/images/monetization/keitaro.svg')" alt="keitaro logo" height="22px" />
                <span> {{ $t("platform.arbitrage.keitaro") }} </span>
                <svg v-svg class="ml-2" symbol="arrow" size="0 0 17 17" role="info"></svg>
              </a>
              <a target="_blank" href="https://guide.partners.house/binom">
                <img :src="require(`@/assets/images/monetization/${isDarkTheme ? 'binom-d.png' : 'binom.webp'}`)" alt="binom logo" height="41px" />
                <span>{{ $t("platform.arbitrage.binom") }}</span>
                <svg v-svg class="ml-2" symbol="arrow" size="0 0 17 17" role="info"></svg>
              </a>
            </div>
          </div>

          <div class="landings">
            <div class="section" :class="{ invalid: $v.platform.lands.$error }">
              <div class="landings__head">
                <h3>{{ $t("platform.arbitrage.choose_landing") }}</h3>
                <p class="max-390">
                  {{ $t("platform.arbitrage.if_more_than_1_landing") }}
                </p>
              </div>
              <Tabs class="land-tabs" :tabs="tabs" :currentTab="currentTab" @setTab="setCurrentTab" />
              <div class="landings__list mt-3">
                <div
                  class="landings__item landing"
                  :class="{
                    list: order === 'list',
                    grid: order === 'grid',
                    active: platform.lands.find((id) => id === land.id),
                  }"
                  v-for="(land, ind) in filteredLandings"
                  :key="ind"
                  @click="addLandingId(land)"
                >
                  <div class="landing__thumb thumb">
                    <img :src="`${land.image}`" alt="" />
                  </div>
                  <div class="landing__body">
                    <div class="landing__title">
                      {{ $i18n.locale === "ru" ? land.name_ru : land.name_en }}
                    </div>
                    <div class="landing__footer">
                      <button @click.stop.prevent="openLandModal(land)" class="btn icon-view">
                        {{ $t("platform.arbitrage.watch") }}
                      </button>

                      <label class="checkbox-landing">
                        <svg
                          class="checkbox-landing__globe"
                          v-tooltip="{
                            html: true,
                            content: `
                              <span class='landing-tooltip__icon'>
                                <svg viewBox='0 0 32 32' width='17' height='17' xmlns='http://www.w3.org/2000/svg'>
                                  <path d='M14.4 24h3.2v-9.6h-3.2v9.6zM16 0c-8.84 0-16 7.16-16 16s7.16 16 16 16c8.84 0 16-7.16 16-16s-7.16-16-16-16zM16 28.8c-7.056 0-12.8-5.744-12.8-12.8s5.744-12.8 12.8-12.8c7.056 0 12.8 5.744 12.8 12.8s-5.744 12.8-12.8 12.8zM14.4 11.2h3.2v-3.2h-3.2v3.2z'></path>
                                </svg>
                              </span>
                              <span>${$t('platform.arbitrage.global')}</span>`,
                            triggers: ['hover'],
                            class: 'landing-tooltip',
                            distance: '10',
                          }"
                          v-svg
                          symbol="globe"
                          role="info"
                        ></svg>
                        <button type="button" @click.stop.prevent="addLandingId(land)">
                          <svg class="checkbox-landing__icon" v-svg symbol="check-land" size="0 0 21 21" role="info"></svg>
                        </button>
                        <span class="checkbox-landing__border"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <p class="text-center mb-0 landings__total" v-if="landings">
                {{ $t("platform.arbitrage.active_landing") }}: <strong>{{ platform.lands.length }}</strong> {{ $t("from") }}
                {{ landings.length }}
              </p>
              <ul class="landings__view">
                <li>
                  <button class="btn _icon _view" :class="{ active: order === 'grid' }" @click.prevent="order = 'grid'">
                    <svg v-svg symbol="grid" size="0 0 14 14" role="info"></svg>
                  </button>
                </li>
                <li>
                  <button class="btn _icon _view" :class="{ active: order === 'list' }" @click.prevent="order = 'list'">
                    <svg v-svg symbol="list" size="0 0 12 12" role="info"></svg>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <modal @closed="clearLandingModal" :width="780" name="landModal" height="auto" :scrollable="true" classes="creative-modal" :shiftY="0">
          <LandingModal :landing="currentLanding" @closeModal="closeLandingModal" />
        </modal>
        <div class="row justify-content-between mx-0 mt-3 d-xl-none w-100">
          <div class="col-12 col-sm-4 col-md-5">
            <div class="form-item pb-0">
              <div class="form-item__field">
                <button type="submit" @click.prevent="createArbitrage" class="btn _mob100">
                  {{ $t("platform.save_platform") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-else-if="!hasAccess && !showModeration">
      <ModerationBlank @send="showModeration = true" />
    </template>
    <template v-else-if="showModeration">
      <ModerationStatus :title="statusTitle" :reason="statusText" :approve_status="user && user.is_moderation_form_exist" />
    </template>
    <alert v-if="$alert.title" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ServiceSubAccs from "@/api/subaccsService";
import ModerationBlank from "@/components/ModerationBlank.vue";
import ModerationStatus from "@/components/ModerationStatus.vue";
import VideoGuide from "@/components/VideoGuide.vue";
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_page-add-platform.scss";
import "@/assets/css/_section.scss";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, url, requiredIf } from "vuelidate/lib/validators";
import LandingModal from "@/components/LandingModal";
import LandingMixin from "@/mixins/landModal";
import TraffbackWithMacros from "@/components/TraffbackWithMacros.vue";
import addMacros from "@/mixins/addMacros";
import ServicePanel from "@/api/servicePanel";
import Tabs from "@/components/statictics/Tabs.vue";
import CheckBoxWrapper from "@/components/CheckBoxWrapper.vue";

export default {
  name: "Arbitrage",
  components: {
    ModerationBlank,
    ModerationStatus,
    LandingModal,
    TraffbackWithMacros,
    Tabs,
    VideoGuide,
    CheckBoxWrapper,
  },
  mixins: [validationMixin, LandingMixin, addMacros],
  data() {
    return {
      showModeration: false,
      hasAccess: true,

      isEdit: false,
      currentPlatform: null,

      landings: [],
      platform: {
        complexity: 3,
        lands: [],
        fullscreen: false,
        name: "",
        // monetizationTb: false,
        // monetizationInpage: false,
        redirect_type: { title: "platform.arbitrage.redirect", value: 0 },

        domain_rotation: {
          title: "platform.arbitrage.random",
          value: "random",
        },
        payment_model: {
          title: "Revshare",
          value: 0,
        },
        tb_subscription: "default",
        tb_complexity: "partners",
        tb_subscription_url: "",
        tb_complexity_url: "",
        postback_url: "",
        enable_onclick_ads: false,
        redirect_to_actual_domain: true,
        enable_in_page_ads: true,
        feedsSales: false,
        mailing_frequency: { title: "Standard", value: "standart" },
      },
      redirectMode: [
        { title: "platform.arbitrage.window_accept", value: 1 },
        { title: "platform.arbitrage.redirect", value: 0 },
        { title: "platform.arbitrage.on_site_click", value: 2 },
      ],
      rotationMode: [
        { title: "platform.arbitrage.random", value: "random" },
        { title: "platform.arbitrage.new_to_old", value: "newest-to-oldest" },
      ],
      tabs: ["all", "adult", "mainstream"],
      currentTab: "all",
      mailings: [
        { title: "Standard", value: "standart" },
        { title: "Maximum", value: "maximum" },
      ],
      paymentOptions: [
        {
          title: "CPL",
          value: 1,
        },
        {
          title: "Revshare",
          value: 0,
        },
      ],
      order: "grid",
      templateModel: null,
      templates: [],
      postback_format: [],
      postback_format_list: [
        {
          title: "Push",
          id: 0,
          value: "push",
        },
        {
          title: "InPage",
          id: 1,
          value: "in_page",
        },
        {
          title: "OnClick",
          id: 2,
          value: "on_click",
        },
        {
          title: "TB Leads",
          id: 3,
          value: "tb_leads",
        },
      ],
    };
  },
  validations: {
    platform: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(52),
      },
      tb_subscription_url: {
        required: requiredIf(function () {
          return this.platform.tb_subscription === "user";
        }),
        url,
        maxLength: maxLength(1024),
      },
      tb_complexity_url: {
        required: requiredIf(function () {
          return this.platform.tb_complexity === "user";
        }),
        url,
        maxLength: maxLength(1024),
      },
      postback_url: {
        url,
        maxLength: maxLength(4096),
      },
      lands: {
        minLength: (value) => {
          return value.length >= 1;
        },
      },
      payment_model: {
        required,
      },
    },
  },
  watch: {
    "platform.enable_onclick_ads": function (val) {
      if (val) this.platform.fullscreen = false;
    },
    templateModel(val) {
      if (val) {
        const selectedTemplate = this.templates.find((el) => el.name == val.name);
        this.platform.postback_url = selectedTemplate.postback_url;
      } else {
        this.platform.postback_url = "";
      }
    },
  },
  mounted() {
    if (!this.user?.confirm) {
      this.hasAccess = false;
      if (this.user?.is_moderation_form_exist) this.showModeration = true;
      if (!this.user?.is_moderation_form_exist) this.showModeration = false;
      return;
    }
    this.getUrlTemplates();

    if (this.$route.fullPath.includes("edit-platform")) {
      this.isEdit = true;
    }

    ServiceSubAccs.getLandings().then((res) => {
      if (res && res.status === 200) {
        this.landings = res.data;
      }
    });

    if (this.isEdit) {
      ServiceSubAccs.getSubAcc(this.$route.params.id)
        .then((res) => {
          if (res && res.status === 200) {
            this.currentPlatform = res.data;
          }
        })
        .then(() => {
          this.platform = {
            complexity: this.currentPlatform.complexity,
            payment_model: this.currentPlatform.payment_model == 1 ? { title: "CPL", value: 1 } : { title: "Revshare", value: 0 },

            redirect_type: this.redirectMode.find((el) => el.value === this.currentPlatform.complexity_redirect),

            mailing_frequency: this.mailings.find((el) => el.value === this.currentPlatform.mailing_frequency),

            tb_subscription: this.currentPlatform.tb_subscription,
            tb_complexity: this.currentPlatform.tb_complexity,
            lands: this.currentPlatform.lands || [],
            fullscreen: this.currentPlatform.fullscreen,
            enable_in_page_ads: this.currentPlatform.enable_in_page_ads,
            name: this.currentPlatform.name,
            tb_subscription_url: this.currentPlatform.tb_subscription_url,
            tb_complexity_url: this.currentPlatform.tb_complexity_url,
            postback_url: this.currentPlatform.postback_url,
            enable_onclick_ads: this.currentPlatform.enable_onclick_ads,
            feedsSales: this.currentPlatform.deny_sell_to_feeds,
            redirect_to_actual_domain: this.currentPlatform.redirect_to_actual_domain,

            domain_rotation: this.rotationMode.find((el) => el.value === this.currentPlatform.redirect_to_personal_domain_settings.strategy) || this.rotationMode[0],
          };
          this.setPostbackValue();
        });
    }
  },
  methods: {
    getUrlTemplates() {
      ServicePanel.getUrlTemplates().then((res) => {
        if (res && res.status === 200) {
          this.templates = res.data;
        }
      });
    },
    setPostbackValue() {
      const map = new Map([
        ["push", "enable_postback_push_revshare"],
        ["in_page", "enable_postback_in_page_revshare"],
        ["on_click", "enable_postback_on_click_revshare"],
        ["tb_leads", "enable_postback_tb_leads"],
      ]);

      map.forEach((value, key) => {
        if (this.currentPlatform[value]) {
          this.postback_format.push(this.postback_format_list.find((el) => el.value === key));
        }
      });
    },
    checkPostbackValue(format) {
      return this.postback_format.some((el) => el.value === format);
    },
    displayOptionHint(value) {
      if (value === "random") return this.$t("platform.arbitrage.random_hint");
      else return this.$t("platform.arbitrage.new_to_old_hint");
    },
    formatLabel(el) {
      return this.$t(el);
    },
    setCurrentTab(tab) {
      this.currentTab = tab;
    },
    addLandingId(el) {
      if (this.platform.lands.find((ind) => ind === el.id)) {
        this.platform.lands = this.platform.lands.filter((ind) => ind !== el.id);
      } else {
        this.platform.lands.push(el.id);
      }
    },
    createArbitrage() {
      this.$v.$touch();

      if (this.checkError) {
        this.$gtm.trackEvent({ event: "create_source_disabled_button" });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }

      this.$gtm.trackEvent({ event: "create_source_submited" });

      const payload = {
        site: 0,
        name: this.platform.name,
        payment_model: this.platform.payment_model.value,
        complexity_redirect: this.platform.redirect_type.value,
        mailing_frequency: this.platform.mailing_frequency.value,
        tb_subscription: this.platform.tb_subscription,
        tb_complexity: this.platform.tb_complexity,
        complexity: this.platform.complexity,
        tb_subscription_url: this.platform.tb_subscription === "user" ? this.platform.tb_subscription_url : undefined,
        tb_complexity_url: this.platform.tb_complexity === "user" ? this.platform.tb_complexity_url : undefined,
        lands: this.platform.lands,
        enable_onclick_ads: this.platform.enable_onclick_ads,
        enable_in_page_ads: this.platform.enable_in_page_ads,
        redirect_to_actual_domain: +this.platform.redirect_to_actual_domain,
        postback_url: this.platform.postback_url,
        fullscreen: +this.platform.fullscreen,
        deny_sell_to_feeds: this.platform.payment_model.value === 0 ? +this.platform.feedsSales : 0,

        redirect_to_personal_domain_settings: {
          active: this.platform.redirect_to_actual_domain,
          strategy: this.platform.redirect_to_actual_domain ? this.platform.domain_rotation.value : undefined,
        },
        enable_postback_in_page_revshare: this.checkPostbackValue("in_page"),
        enable_postback_on_click_revshare: this.checkPostbackValue("on_click"),
        enable_postback_push_revshare: this.checkPostbackValue("push"),
        enable_postback_tb_leads: this.checkPostbackValue("tb_leads"),
      };

      if (this.isEdit) {
        if (!this.checkError) {
          let params = {
            // general variables for platforms
            name: this.currentPlatform.name,
            complexity: +this.currentPlatform.complexity,
            tb_subscription: this.currentPlatform.tb_subscription,
            tb_complexity: this.currentPlatform.tb_complexity,
            tb_subscription_url: this.currentPlatform.tb_subscription_url,
            tb_complexity_url: this.currentPlatform.tb_complexity_url,
            mailing_frequency: this.currentPlatform.mailing_frequency,
            adult: +this.currentPlatform.adult,
            fullscreen: +this.currentPlatform.fullscreen,
            lands: this.currentPlatform.lands || [],
            postback_url: this.currentPlatform.postback_url,
          };
          if (!this.currentPlatform.site) {
            params.payment_model = +this.currentPlatform.payment_model.value;
          }
          params = Object.assign(params, payload);
          ServiceSubAccs.updateSubAcc({
            id: this.$route.params.id,
            params,
          }).then((res) => {
            if (res) {
              if (res.status === 200) {
                this.$alert({
                  title: this.$t("sent_success"),
                  text: res.message,
                  type: "success",
                });
                this.$router.push("/platforms");
              } else {
                this.$alert({
                  title: this.$t("sent_error"),
                  text: res.message,
                });
              }
            }
          });
          this.$v.$reset();
        }
      } else {
        if (!this.checkError) {
          ServiceSubAccs.createSubAcc(payload).then((res) => {
            if (res) {
              if (res.status === 200) {
                this.$gtm.trackEvent({ event: "create_source_success" });
                this.$alert({
                  title: this.$t("sent_success"),
                  text: res.message,
                  type: "success",
                });
                this.$router.push("/platforms");
              } else {
                this.$gtm.trackEvent({ event: "create_source_failure" });
                this.$alert({
                  title: this.$t("sent_error"),
                  text: res.message,
                });
              }
            }
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      isDarkTheme: "user/isDarkTheme",
    }),
    siteVideoGuide() {
      return this.$i18n.locale === "ru" ? "https://youtu.be/aVCowBx33FA" : "https://youtu.be/OQkjFenNb9E";
    },
    // moderation block
    statusTitle() {
      if (this.user?.moderation_form_reject_reason) return this.$t("platform.application_fail");
      else return this.$t("platform.application_sent");
    },
    statusText() {
      if (this.user?.moderation_form_reject_reason) return this.user.moderation_form_reject_reason;
      else return this.$t("platform.application_wait");
    },

    filteredLandings() {
      if (this.currentTab === "adult") {
        return this.landings.filter((el) => el.category == 1);
      } else if (this.currentTab === "mainstream") {
        return this.landings.filter((el) => el.category == 2);
      } else return this.landings.map((el) => el);
    },
    checkError() {
      const generalError = this.$v.platform.lands.$invalid || this.$v.platform.name.$invalid || this.$v.platform.payment_model.$invalid || this.$v.platform.postback_url.$invalid;
      if (this.platform.tb_complexity !== "user" && this.platform.tb_subscription !== "user") {
        return generalError;
      } else if (this.platform.tb_complexity !== "user" && this.platform.tb_subscription === "user") {
        return generalError || this.$v.platform.tb_subscription_url.$invalid;
      } else if (this.platform.tb_complexity === "user" && this.platform.tb_subscription !== "user") {
        return generalError || this.$v.platform.tb_complexity_url.$invalid;
      } else {
        return this.$v.$invalid;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.land-tabs ::v-deep li {
  width: 33%;
}

.option-hint {
  width: 95%;
  padding-top: 4px;
  white-space: normal;
}

@media screen and (max-width: 1200px) {
  .d-none-mobile {
    display: none;
  }
}

.info-popup.w-160 {
  width: 160px;
}

.h-fit {
  height: fit-content;
}

.fix-height {
  padding-top: 30px;
  height: 130px;
  .form-item__field {
    margin-bottom: 4px;
  }
}
.small-height {
  padding-top: 42px;
}

.form-item__field label.disabled {
  cursor: not-allowed;
}

.warn-block {
  display: flex;
  align-items: start;
  column-gap: 6px;
  p {
    margin: 0;
    font-size: 14px;
    color: #626262;
  }
  svg {
    width: 40px;
    fill: #626262;
  }
}
.gap-3 {
  gap: 16px;
}

.invalid {
  border: 1px solid red;
}

.complex-select ::v-deep .multiselect__content-wrapper {
  max-height: 175px !important;
}

.traffback {
  display: flex;
  column-gap: 16px;
  row-gap: 16px;
}

@media screen and (max-width: 768px) {
  .traffback {
    flex-flow: column wrap;
  }
}

.multiselect.payment {
  &::v-deep {
    .multiselect__content-wrapper li {
      color: #000;
    }
  }
}

.links-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  width: 100%;

  img {
    width: 125px;
  }

  a {
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    gap: 0 4px;
    padding: 12px 26px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    svg {
      fill: #626262;
    }
  }

  @media (max-width: 576px) {
    img {
      width: auto;
    }

    a {
      padding: 8px;
    }
  }
}

.checkbox-switch__text {
  width: fit-content;
}

.checkbox-arbitrage-wrapper {
  @media (min-width: 576px) {
    justify-content: end;
  }
}

.disabled_cpl {
  &::v-deep {
    .custom-multiselect-list .custom-multiselect-list__block:first-child {
      pointer-events: none;
      background: #ededed !important;
      color: #676767 !important;
    }
  }
}
</style>
