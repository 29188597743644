<template>
  <label class="checkbox-switch" :class="{disabled:disabled}">
    <input type="checkbox" v-model="data" :checked="data" :disabled="disabled"/>
    <span class="checkbox-switch__visible"></span>
    <span class="checkbox-switch__text">{{ $t(text) }}</span>
  </label>
</template>
<script>
export default {
  name: "CheckBox",
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      required: true,
      type: String,
    },
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
};
</script>

<style scoped>
.checkbox-switch.disabled { 
  cursor: not-allowed;
  opacity: 0.5;
}
</style>